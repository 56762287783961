.popup__container_type_tooltip {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    position: relative;
    width: 430px;
    min-width: 260px;
    background-color: #fff;
    border-radius: 10px;
    padding: 60px 36px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    transition: all .5s ease;
}

@media screen and (max-width: 615px) {
    .popup__container_type_tooltip {
        max-width: 100%;
    }   
}

@media screen and (max-width: 425px) {
    .popup__container_type_tooltip {
        padding: 50px 18px;
        gap: 40px;
    }   
}