.profile__profile-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    padding: 0 0 12px;
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    transition: all .5s ease;
}

@media screen and (max-width: 615px) {
    .profile__profile-name {
        max-width: 294px;
        text-align: center;
        padding: 26px 0 14px;
    }   
}

@media screen and (max-width: 425px) {
    .profile__profile-name {
        max-width: 189px;
        text-align: center;
        font-size: 27px;
        line-height: 33px;
    }   
}
