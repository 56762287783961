.profile__profile-description {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    transition: all .5s ease;
}

@media screen and (max-width: 615px) {
    .profile__profile-description {
        text-align: center;
        padding: 0 0 36px;
    }   
}

@media screen and (max-width: 425px) {
    .profile__profile-description {
        font-size: 14px;
        line-height: 17px;
    }   
}