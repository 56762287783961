.card__title {
    color: var(--black);
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    padding: 25px 0 0;
    margin: 0;
    max-width: calc(100% - 60px);
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}