.auth__submit-button {
    width: 100%;
    background-color: var(--white);
    color: var(--black);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    padding: 0;
    min-height: 50px;
    margin-top: auto;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    opacity: 1;
    transition: background-color .15s ease, all .5s ease;
}

.auth__submit-button:hover {
    background-color: rgba(255, 255, 255, .85);
    cursor: pointer;
}

@media screen and (max-width: 425px) {
    .auth__submit-button {
        padding: 14px 0;
        font-size: 16px;
    }   
}
