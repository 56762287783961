.auth__input {
    width: 100%;
    border: none;
    background-color: var(--black);
    border-bottom: 2px var(--light-gray) solid;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    outline: none;
    box-sizing: border-box;
    transition: border-bottom .5s ease;
    color: var(--white);
}

.auth__input:first-of-type {
    margin-top: 50px;
}

@media screen and (max-width: 425px) {
    .auth__input:first-of-type {
        margin-top: 40px;
    }   
}