.footer__copyright {
    color: var(--dark-gray);
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    transition: all .5s ease;
}

@media screen and (max-width: 425px) {
    .footer__copyright {
        font-size: 14px;
        line-height: 17px;
    }   
}