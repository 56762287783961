.profile__avatar-edit-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    background-image: url(../../../images/edit-icon.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 26px;
    cursor: pointer;
    opacity: 0;
    transition: all .5s ease;
}

.profile__avatar-edit-button:hover {
    opacity: 1;
}
