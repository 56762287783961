.card {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 282px;
    background-color: var(--white);
    border-radius: 10px;
    overflow: hidden;
    transition: all .5s ease;
}

@media screen and (max-width: 425px) {
    .card {
        max-width: 100%;
    }   
}