.profile {
    display: grid;
    grid-template-columns: 120px minmax(0, auto) minmax(50px, 150px);
    gap: 30px;
    padding: 40px 0 50px;
    transition: all .5s ease;
}

@media screen and (max-width: 933px) {
    .profile {
        padding: 40px 20px 50px;
    }   
}

@media screen and (max-width: 615px) {
    .profile {
        grid-template-columns: 100%;
        gap: 0;
        justify-items: center;
    }   
}

@media screen and (max-width: 425px) {
    .profile {
        padding: 42px 20px 36px;
    }   
}