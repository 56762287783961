.card__delete-button {
    background-color: unset;
    background-image: url(../../../images/delete.svg);
    background-repeat: no-repeat;
    border: none;
    width: 18px;
    height: 19px;
    opacity: 1;
    transition: opacity .15s ease;
}

.card__delete-button:hover {
    opacity: .6;
    cursor: pointer;
}