.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 880px;
    min-width: 320px;
    margin: 0 auto;
    line-height: 0;
    background-color: var(--black);
    font-family: 'Inter', sans-serif;
    color: var(--white);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
}