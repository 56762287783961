.auth__title {
    color: var(--white);
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    margin: 0;
    box-sizing: border-box;
    transition: all .5s ease;
    cursor: default;
}

@media screen and (max-width: 425px) {
    .auth__title {
        font-size: 20px;
    }   
}

