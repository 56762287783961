.popup__title {
    color: #000;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    margin: 0;
    padding-bottom: 24px;
    box-sizing: border-box;
    transition: all .5s ease;
}

@media screen and (max-width: 425px) {
    .popup__title {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 75px;
    }   
}

