.popup__input {
    width: 100%;
    border: none;
    border-bottom: 1px rgba(0,0,0,.2) solid;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    outline: none;
    box-sizing: border-box;
    transition: border-bottom .5s ease;
}

.popup__input:first-of-type {
    padding-top: 24px;
}