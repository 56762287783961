.profile__profile-info {
    max-width: 336px;
    align-self: center;
    transition: all .5s ease;
}

@media screen and (max-width: 425px) {
    .profile__profile-info {
        width: 100%;
    }   
}
