.elements {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 17px;
    row-gap: 20px;
    transition: all .5s ease;
}

@media screen and (max-width: 933px) {
    .elements {
        padding: 0 20px;
    }   
}

@media screen and (max-width: 615px) {
    .elements {
        grid-template-columns: 1fr 1fr;
    }   
}

@media screen and (max-width: 425px) {
    .elements {
        grid-template-columns: 1fr;
    }   
}