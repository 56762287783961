.auth__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 430px;
    height: 100%;
    padding: 60px 35px 80px;
    box-sizing: border-box;
    transition: all .5s ease;
}

@media screen and (max-width: 615px) {
    .auth__container {
        max-width: 100%;
    }   
}

@media screen and (max-width: 425px) {
    .auth__container {
        padding: 40px 30px;
        gap: 20px;
    }   
}