.card__delete-button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: radial-gradient(ellipse at center, rgba(0,0,0,.2), rgba(0,0,0,0) 50%);
}