.popup__input-error {
    padding-top: 5px;
    display: inline-block;
    width: 100%;
    border: none;
    color: rgba(255,0,0,1);
    font-size: 12px;
    line-height: 15px;
    min-height: 35px;
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease;
}