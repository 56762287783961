.popup__submit-button {
    width: 100%;
    background-color: #000;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    padding: 0;
    min-height: 50px;
    margin: 8px 0 0;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    opacity: 1;
    transition: background-color .15s ease, all .5s ease;
}

.popup__submit-button:hover {
    background-color: rgba(0, 0, 0, .8);
    cursor: pointer;
}

@media screen and (max-width: 425px) {
    .popup__submit-button {
        padding: 14px 0;
        font-size: 14px;
        line-height: 17px;
    }   
}
