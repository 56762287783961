.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0,0,0,.5);
    visibility: hidden;
    opacity: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: all .15s ease;
}

@media screen and (max-width: 615px) {
    .popup {
        padding: 0 20px;
    }   
}

