.tooltip__title {
    color: #000;
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    margin: 0;
    text-align: center;
    box-sizing: border-box;
    transition: all .5s ease;
}

@media screen and (max-width: 425px) {
    .tooltip__title {
        font-size: 20px;
    }   
}

