.popup__close-button {
    background-color: unset;
    border: none;
    position: absolute;
    top: -40px;
    right: -40px;
    min-width: 32px;
    min-height: 32px;
    box-sizing: border-box;
    background-image: url(../../../images/close-icon.svg);
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    transition: opacity .15s ease, all .5s ease;
}

.popup__close-button:hover {
    opacity: .6;
    cursor: pointer;
}

@media screen and (max-width: 615px) {
    .popup__close-button {
        min-width: 20px;
        min-height: 20px;
        background-size: 20px;
        top: -36px;
        right: 0;
    }   
}