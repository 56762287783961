.header__logo {
    max-width: 142px;
    object-fit: contain;
    object-position: left;
    max-height: 100%;
    box-sizing: contain-box;
    transition: all .5s ease;
}

@media screen and (max-width: 425px) {
    .header__logo {
        align-self: flex-start;
        max-width: 104px;
        margin: 27px 30px 32px;
    }   
}