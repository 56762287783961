.header__menu-burger {
    position: absolute;
    right: 30px;
    background-color: unset;
    border: none;
    min-width: 24px;
    min-height: 24px;
    box-sizing: border-box;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    cursor: pointer;
    display: none;
    transition: opacity .15s ease, all .5s ease;
}

.header__menu-burger:hover {
    opacity: .6;
}

@media screen and (max-width: 425px) {
    .header__menu-burger {
        display: block;
        transform: translateY(-32px);
    }   
}