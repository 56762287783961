.profile__add-button {
    align-self: center;
    min-height: 50px;
    background-color: unset;
    background-image: url(../../../images/add-icon.svg);
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px var(--white) solid;
    border-radius: 2px;
    padding: 0;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity .15s ease, all .5s ease;
}

.profile__add-button:hover {
    opacity: .6;
    cursor: pointer;
}

@media screen and (max-width: 615px) {
    .profile__add-button {
        width: 100%;
    }   
}

@media screen and (max-width: 425px) {
    .profile__add-button {
        background-size: 16px;
    }   
}