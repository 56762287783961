@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("./font/Inter-Regular.woff2") format("woff2");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("./font/Inter-Medium.woff2") format("woff2");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("./font/Inter-Black.woff2") format("woff2");
}